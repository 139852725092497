<script setup>
import {homepageIndex} from "~/api/api.homepage";
import {basicProductListByRecentlyViewed, basicProductListByYouLike} from "~/api/api.basic";
import {wishlistList} from "~/api/api.wishlist";
import _ from 'lodash'
import {PATH_BRANDS} from "~/utils/constants";
import Streamer from "~/components/Streamer.vue";
import HomeSkeleton from "~/components/home/HomeContent/HomeSkeleton.vue";
import ModuleCarouselCount6 from "~/components/ModuleCarouselCount6.vue";
import ModuleCarouselCount6Skeleton from "~/components/ModuleCarouselCount6Skeleton.vue";
import {PAGE_LABEL} from "~/utils/smartlook";
import { markRaw } from 'vue';

/** data **/
const homeData = ref({userSections: []})
const components = ref({})

const isRecentlyViewedLoading = ref(false)
const isYouMayAlsoLikeLoading = ref(false)
const isWishListLoading = ref(false)
const recentlyViewed = ref({title: "", list: []})
const yourWishList = ref({title: "", list: []})
const youMayAlsoLike = ref({title: "", list: []})

/** watch **/
watch(homeData, (newV) => {
  components.value = {
    topBanner: {
      component: markRaw(defineAsyncComponent(() => import("~/components/home/HomeContent/HomeTopBanner.vue"))),
      props: {topBanner: newV.topBanner},
      events: {}
    },
    introductionArea: {
      component: markRaw(defineAsyncComponent(() => import("~/components/home/HomeContent/HomeBNTOIntroduce.vue"))),
      props: {introduce: newV.introduceArea},
      events: {}
    },
    priceArea: {
      component: markRaw(defineAsyncComponent(() => import("~/components/home/HomeContent/HomePlan&Pricing.vue"))),
      props: {planPricing: newV.priceArea},
      events: {}
    },
    brandsArea: {
      component: markRaw(defineAsyncComponent(() => import("~/components/home/HomeContent/HomeBrandLogos.vue"))),
      props: {brands: newV.brandsArea},
      events: {}
    },
    displayArea: {
      component: markRaw(defineAsyncComponent(() => import("~/components/home/HomeContent/HomeCollections.vue"))),
      props: {collections: newV.displayArea},
      events: {}
    },
    registerArea: {
      component: markRaw(defineAsyncComponent(() => import("~/components/home/HomeContent/HomeEmailRegister.vue"))),
      props: {register: newV.registerArea},
      events: {}
    },
    carouselArea: {
      component: markRaw(defineAsyncComponent(() => import("~/components/home/HomeContent/HomeBrandRecommendCarousel.vue"))),
      props: {
        subtitle: newV.carouselArea.subTitle,
        title: newV.carouselArea.title,
        list: newV.carouselArea.result,
        btnViewAll: {
          visible: true,
          text: "view all brands",
          link: PATH_BRANDS
        }
      },
      events: {}
    },
    availableArea: {
      component: markRaw(defineAsyncComponent(() => import("~/components/ModuleCarouselCount10.vue"))),
      props: {carousel: newV.availableArea},
      events: {}
    },
  }
}, {deep: true})

/** methods **/
const getData = async () => {
  const { data } = await useAsyncData(() => homepageIndex())
  // 将sections中过滤出异步模块
  const asyncElements = ["recentlyViewed", "yourWishList", "youMayAlsoLike"]
  homeData.value = {...data.value.result, userSections: data.value.result.userSections.split(',').filter(item => !asyncElements.includes(item))}
  console.log("homepageIndex", homeData.value)
  // 异步获取其他数据
  if (data.value.result.userSections.includes("recentlyViewed")) {
    getRecentlyViewed().then(data => {
      recentlyViewed.value = data
    })
  }
  if (data.value.result.userSections.includes("yourWishList")) {
    getYourWishList().then(data => {
      yourWishList.value = data
    })
  }
  if (data.value.result.userSections.includes("youMayAlsoLike")) {
    getYouMayAlsoLike().then(data => {
      youMayAlsoLike.value = data
    })
  }
}
/** recently viewed **/
const getRecentlyViewed = async () => {
  isRecentlyViewedLoading.value = true
  const {data, pending, error, refresh} = await useAsyncData(() => basicProductListByRecentlyViewed({
    pageIndex: 1,
    pageSize: 18
  }).catch(e => console.error("basicProductListByRecentlyViewed", e)))
  console.log('recentlyViewed', data.value)
  isRecentlyViewedLoading.value = false
  return {title: "recently viewed", list: data.value.result.list}
}
/** your wish list **/
const getYourWishList = async () => {
  isWishListLoading.value = true
  const {data, pending, error, refresh} = await useAsyncData(() => wishlistList({
    pageIndex: 1,
    pageSize: 18
  }).catch(e => console.error("wishlistList", e)))
  console.log('yourWishList', data.value)
  isWishListLoading.value = false
  return {title: 'your wish list', list: data.value.result.list}
}
/** you may also like **/
const getYouMayAlsoLike = async () => {
  isYouMayAlsoLikeLoading.value = true
  const {data, pending, error, refresh} = await useAsyncData(() => basicProductListByYouLike({
    pageIndex: 1,
    pageSize: 18
  }).catch(e => console.error("basicProductListByYouLike", e)))
  console.log('youMayAlsoLike', data.value)
  isYouMayAlsoLikeLoading.value = false
  return {title: 'you may also like', list: data.value.result.list}
}

/** lifecycle **/
const title = "MEET BNTO | The AI-powered Fashion Rental Subscription Service"
const description = "The AI-powered Fashion Rental Subscription Service.More style, less money, infinite inspiration.Fashion on Rotation - Rent 6 items from coveted global brands starting at $98/month.One subscription, limitless outfits. Purchase your favorites at up to 75% off.Get personalized fashion advice and expand your style horizons with BNTO AI, your personal stylist.Enjoy $20 bonus credits each month, redeemable on rental purchases. Earn more by reviewing products or sharing BNTO with friends.No late fees, free shipping and returns, guaranteed cleaning. Pause or cancel anytime."
useSeoMeta({
  title: title,
  ogTitle: title,
  description: description,
  ogDescription: description,
  ogImage: '/og-image.png',
  ogSiteName: "BNTO",
  ogLocale: "en-US",
  ogType: "website",
  ogUrl: useRequestURL().href,
  twitterCard: 'summary_large_image',
})

getData()
onMounted(() => {
  useBaseStore().updatePageLabel(PAGE_LABEL.HOME)
})
onActivated(() => {
  useBaseStore().updatePageLabel(PAGE_LABEL.HOME)
})

</script>

<template>
  <streamer/>
  <home-skeleton v-if="_.isEmpty(components)"/>
  <div class="content" v-else>
    <component
      v-for="(name, index) in homeData.userSections"
      :is="components[name].component"
      v-bind="components[name].props"
      v-on="components[name].events"
    />
    <!-- recentlyViewed -->
    <module-carousel-count6-skeleton v-if="isRecentlyViewedLoading"/>
    <module-carousel-count6 v-else-if="!isRecentlyViewedLoading && recentlyViewed.list.length" :title="recentlyViewed.title" :list="recentlyViewed.list"/>
    <!-- yourWishList -->
    <module-carousel-count6-skeleton v-if="isWishListLoading"/>
    <module-carousel-count6 v-else-if="!isWishListLoading && yourWishList.list.length" :title="yourWishList.title" :list="yourWishList.list"/>
    <!-- youMayAlsoLike -->
    <module-carousel-count6-skeleton v-if="isYouMayAlsoLikeLoading"/>
    <module-carousel-count6 v-else-if="!isYouMayAlsoLikeLoading && youMayAlsoLike.list.length" :title="youMayAlsoLike.title" :list="youMayAlsoLike.list"/>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.content {
  min-height: 100vh;
}
</style>
